import * as React from "react";
import Layout from "../../components/layout";
import { Button, Spinner, Collapse } from "react-bootstrap";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import api, { catcher } from "../../services/api";

const PendingConfirmView = ({ transaction }) => {
    const [open, setOpen] = React.useState(false);
    
    return (
        <Layout title="Detail Transaksi" topNav bg="light">
            <p>Wait for Confirm</p>
            <div className="bg-white py-3 mb-3 shadow-sm">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>Rincian transaksi</div>
                        <Button
                            variant="link"
                            size="sm"
                            onClick={() => setOpen(!open)}
                        >
                            Lihat
                        </Button>
                    </div>
    
                    <Collapse in={open}>
                        <ul className="list-group rounded-0">
                            {transaction.data.products.map((product) => (
                                <li className="list-group-item lh-condensed d-flex justify-content-between">
                                    <span>{product.name}</span>
                                    <span className="text-muted">
                                        {"Rp" +
                                            product.price.toLocaleString(
                                                "id"
                                            )}
                                    </span>
                                </li>
                            ))}
                            <li className="list-group-item lh-condensed d-flex justify-content-between text-muted bg-light">
                                <span>Kode bayar</span>
                                <span className="text-muted">
                                    {transaction.data.payCodeSuffix}
                                </span>
                            </li>
                            <li className="list-group-item lh-condensed d-flex justify-content-between font-weight-bold">
                                <span>Total</span>
                                <span className="text-muted">
                                    {"Rp" +
                                        (
                                            transaction.data.products.reduce(
                                                (acc, { price }) =>
                                                    acc + price,
                                                0
                                            ) +
                                            transaction.data.payCodeSuffix
                                        ).toLocaleString("id")}
                                </span>
                            </li>
                        </ul>
                    </Collapse>
                </div>
            </div>
        </Layout>
    )
}
    
const PendingPayView = ({ transaction }) => {
    const [open, setOpen] = React.useState(false);
    
    return (
        <Layout title="Detail Transaksi" topNav bg="light">
            <div style={{ height: 15 }} />
            <div className="bg-white py-3 mb-3 shadow-sm">
                <div className="container">
                    <h5>Total</h5>
                    <div className="d-flex justify-content-between align-items-center p-2 border rounded bg-light">
                        <strong>Rp85.013</strong>
                        <Button variant="outline-warning" size="sm">
                            Copy
                        </Button>
                    </div>
                </div>
            </div>
            <div className="bg-white py-3 mb-3 shadow-sm">
                <div className="container">
                    <h5>Transfer ke</h5>
                    <div className="d-flex justify-content-between align-items-center p-2 border rounded bg-light">
                        <strong>0809208413214 a/n Fajrin</strong>
                        <Button variant="outline-warning" size="sm">
                            Copy
                        </Button>
                    </div>
                </div>
            </div>
            <div className="bg-white py-3 mb-3 shadow-sm">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>Rincian transaksi</div>
                        <Button
                            variant="link"
                            size="sm"
                            onClick={() => setOpen(!open)}
                        >
                            Lihat
                        </Button>
                    </div>
    
                    <Collapse in={open}>
                        <ul className="list-group rounded-0">
                            {transaction.data.products.map((product) => (
                                <li className="list-group-item lh-condensed d-flex justify-content-between">
                                    <span>{product.name}</span>
                                    <span className="text-muted">
                                        {"Rp" +
                                            product.price.toLocaleString(
                                                "id"
                                            )}
                                    </span>
                                </li>
                            ))}
                            <li className="list-group-item lh-condensed d-flex justify-content-between text-muted bg-light">
                                <span>Kode bayar</span>
                                <span className="text-muted">
                                    {transaction.data.payCodeSuffix}
                                </span>
                            </li>
                            <li className="list-group-item lh-condensed d-flex justify-content-between font-weight-bold">
                                <span>Total</span>
                                <span className="text-muted">
                                    {"Rp" +
                                        (
                                            transaction.data.products.reduce(
                                                (acc, { price }) =>
                                                    acc + price,
                                                0
                                            ) +
                                            transaction.data.payCodeSuffix
                                        ).toLocaleString("id")}
                                </span>
                            </li>
                        </ul>
                    </Collapse>
                </div>
            </div>
            <div className="py-3">
                <div className="container text-right">
                    <Button variant="link" className="text-danger">
                        Batalkan
                    </Button>{" "}
                    <Button variant="primary">
                        Sudah transfer
                    </Button>
                </div>
            </div>
        </Layout>
    )
}

export default function Detail() {
    const [transactionId] = useQueryParam("id", StringParam);
    const [transaction, setTransaction] = React.useState({});

    React.useEffect(() => {
        api.get("/transactions/" + transactionId)
            .then(setTransaction)
            .catch(catcher);
    }, [transactionId]);

    if (transaction.data?.status === "pendingPay")
        return <PendingPayView transaction={transaction} />
        
    if (transaction.data?.status === "pendingConfirm")
        return <PendingConfirmView transaction={transaction} />

    return (
        <Layout title="Detail Transaksi" topNav>
            {JSON.stringify(transaction)}
        </Layout>
    );
}
